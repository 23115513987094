body {
    background-color: rgb(40 40 42) !important;
}

/* Let's style the wrapper in such away that,
it should always be at the center of the browser */

/* Style the Un-order list by setting its list-style to none */
.wrapper ul {
    list-style: none;
}

/* Style the list items inside the UL list, by setting its width, height and line-height
  and float them to left and set its border and border-radius.
 */
.wrapper ul li {
    width: 75px;
    height: 75px;
    line-height: 75px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid #D8E2DC;
    float: left;
    transition: all 0.5s ease;
}

/* Style the icons by setting its color and margin-top value to 20px
to align it properly */
.wrapper ul li .fa {
    color: #D8E2DC;
    margin-top: 17px;
    transition: all 0.5s ease;
}

/*twitter*/
.wrapper ul li:hover.twitter {
    border: 5px solid #00aced;
    box-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-twitter {
    color: #00aced;
    text-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
}

/*discord*/
.wrapper ul li:hover.discord {
    border: 5px solid #7289DA;
    box-shadow: 0 0 15px #7289DA;
    transition: all 0.5s ease;
}

.wrapper ul li:hover .fa-discord {
    color: #7289DA;
    text-shadow: 0 0 15px #7289DA;
    transition: all 0.5s ease;
}


/* media queries */
@media screen and (max-width: 640px){
    .wrapper {
        width: 350px;
    }
    .wrapper ul li{
        margin-top: 10px;
    }
    .wrapper ul li.google{
        margin-left: 60px;
    }
}


@media screen and (max-width: 340px){
    .wrapper {
        width: 150px;
    }
    .wrapper ul li{
        margin:15px;
    }
    .wrapper ul li.google{
        margin-left: 15px;
    }
}