.link {
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 5px 0;
    color: #fff;
}

.link-wrapper {
    position: relative;
    display: block;
    padding: 20px 0;
}

.inner-wrapper {
    position: relative;
    display: inline-block;
}

.hover-2::after {
     content: '';
     position: absolute;
     bottom: 0;
     left: 0;
     right: 0;
     width: 100%;
     height: 2px;
     transform: scaleX(0);
     background-color: #FFEA00;
     transition: transform 0.3s;
}
.hover-2:hover::after {
    transform: scaleX(1);
}
